@use "sass:string";

@import "./fonts/inter.scss";
@import "./fonts/noto-sans-georgian.scss";
@import "./fonts/noto-sans.scss";
@import "./animations.scss";

:root {
  --font-main: "Noto Sans Georgian", "Noto Sans", sans-serif;
  --color-darkest: #000000;
  --color-darkest-rgb: 0, 0, 0;
  --color-iq-tuna: #33303c;
  --color-mine-shaft: #313131;
  --color-dove-gray: #6f6f6f;
  --color-iq-silver: #cdcdcd;
  --color-bon-jour: #e1dfe1;
  --color-wild-sand: #f5f5f5;
  --color-iq-pampas: #f6f4f0;
  --color-soft-white: #fffdfb;
  --color-white: #ffffff;
  --color-iq-blue: #7b61ff;
  --color-turquoise: #00d1d2;
  --color-pomegranate: #f32a2a;
  --color-taste-yellow: #eac702;
  --color-taste-amber: #ca5e21;
  --color-taste-silver: #c9c9c9;
  --color-taste-turquoise: #03b5b6;
  --color-taste-purple: #baa5ce;
  --color-taste-noor: #c37625;
  --color-taste-apricity: #ff9052;
  --color-taste-yugen: #b95ec2;
}

* {
  margin: 0;
  padding: 0;
  // Override font of @pmi/ui-react components.
  font-family: var(--font-main) !important;
}

body {
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 24px;
}

$marginTops: 12, 24, 36, 48;
@each $marginTop in $marginTops {
  .mt-#{$marginTop} {
    margin-top: #{$marginTop}px;
  }
}

$fontSizes: 12 12, 14 24, 16 24;
@each $fontSize, $lineHeight in $fontSizes {
  .fs-#{$fontSize}-#{$lineHeight} {
    font-size: #{$fontSize}px;
    line-height: #{$lineHeight}px;
  }
}

$fontWeights: 400, 500, 600, 700;
@each $fontWeight in $fontWeights {
  .fw-#{$fontWeight} {
    font-weight: $fontWeight;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-100px, 0);
    opacity: 0;
  }
}

.reversed {
  transform: scaleX(-1);
}

.warrantyProduct {
  display: grid;
  grid-template-columns: auto 240px 240px;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.device-dialog {
  .dialog-actions {
    width: 100%;
  }
}

.no-scroll {
  overflow-x: hidden;
}

.fw-bold {
  font-weight: 700;
}
