@import "../../scss/mixins/appearance.scss";
@import "../../scss/mixins/flex.scss";

.menu {
  position: relative;
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  padding: 12px 40px;
  font-family: var(--font-main);
  @include flex(column, space-between, center);

  .firstRow {
    @include flex(row, space-between, center);
    width: 100%;
  }

  .secondRow {
    @include flex(row, center, center);
    width: 100%;
  }

  .logo {
    $width: 109px;
    $height: 36px;
    width: $width;
    height: $height;
  }

  .title {
    color: var(--color-iq-silver);
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
  }

  .sidePanel {
    width: 120px;
  }

  .back {
    @include flex(row, flex-start, center);
  }

  .logout {
    span {
      margin-right: 0 !important;
    }
  }

  .languages {
    @include flex(row, flex-end, center);
    @include flex-row-gap(24px, "*");

    .language {
      @include appearance(none);
      @include flex(row, center, center);
      font-family: var(--font-main);
      width: 24px;
      height: 24px;
      cursor: pointer;

      &::after {
        $transitionTime: 200ms;
        $width: 17px;
        $height: 24px;

        @include flex(row, center, center);
        width: $width;
        height: $height;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-iq-tuna);
        padding-bottom: 5px;
        box-sizing: border-box;
        border-bottom: 1px solid transparent;
        transition: color $transitionTime, border-color $transitionTime;
      }

      &.ka::after {
        content: "Ka";
      }

      &.en::after {
        content: "En";
      }

      &:checked {
        &::after {
          color: var(--color-turquoise);
          border-bottom: 1px solid var(--color-turquoise);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 12px 20px;

    .languages {
      @include flex-row-gap(8px, "*");
    }

    .back {
      span:nth-child(2) {
        display: none;
      }
    }
  }
}
