@import "../../scss//mixins/flex.scss";

div.withPreloader {
  position: relative;
  width: 100%;

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    transition: opacity 200ms;
    background: var(--color-white);
    opacity: 0.75;
    cursor: progress;

    &.hidden {
      opacity: 0;
      pointer-events: none;
      cursor: auto;
    }
  }
}
