.page-switch {
  &-enter {
    opacity: 0;
    transform: translate(100px, 0);
  }

  &-enter-active {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: opacity 500ms, transform 500ms;
  }

  &-exit {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  &-exit-active {
    opacity: 0;
    transform: translate(-100px, 0);
    transition: opacity 500ms, transform 500ms;
  }
}

.menu-title-fade {
  &-enter {
    opacity: 0;
    transform: translate(0, -12px);
  }

  &-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 200ms, transform 200ms;
  }

  &-exit {
    opacity: 1;
    transform: translate(0, 0);
  }

  &-exit-active {
    opacity: 0;
    transform: translate(0, 12px);
    transition: opacity 200ms, transform 200ms;
  }
}

.menu-fade {
  &-enter {
    opacity: 0;
    transform: translate(-18px, 0);
  }

  &-enter-active {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: opacity 200ms, transform 200ms;
  }

  &-exit {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  &-exit-active {
    opacity: 0;
    transform: translate(-18px, 0);
    transition: opacity 200ms, transform 200ms;
  }
}

.modal-fade {
  &-enter {
    opacity: 0;
    transform: translate(0, -24px);
  }

  &-enter-active {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: opacity 200ms, transform 200ms;
  }

  &-exit {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  &-exit-active {
    opacity: 0;
    transform: translate(0, -24px);
    transition: opacity 200ms, transform 200ms;
  }
}

.table-fade {
  &-enter {
    opacity: 0;
    transform: translate(50px, 0);
  }

  &-enter-active {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: opacity 200ms, transform 200ms;
  }

  &-exit {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transform: translate(-50px, 0px);
    transition: opacity 200ms, transform 200ms;
  }
}
