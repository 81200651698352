@mixin flex($flexDirection, $justifyContent: null, $alignItems: null) {
  display: flex;
  flex-direction: $flexDirection;

  @if $justifyContent {
    justify-content: $justifyContent;
  }

  @if $alignItems {
    align-items: $alignItems;
  }
}

@mixin flex-row-gap($gap, $fallbackSelector, $fallbackGap: null) {
  @supports (display: flex) and (gap: $gap) {
    gap: $gap;
  }

  @supports (display: flex) and (not (gap: $gap)) {
    #{$fallbackSelector} + #{$fallbackSelector} {
      @if ($fallbackGap != null) {
        margin-left: $fallbackGap;
      } @else {
        margin-left: $gap;
      }
    }
  }
}

@mixin flex-column-gap($gap, $fallbackSelector, $fallbackGap: null) {
  @supports (display: flex) and (gap: $gap) {
    gap: $gap;
  }

  @supports (display: flex) and (not (gap: $gap)) {
    #{$fallbackSelector} + #{$fallbackSelector} {
      @if ($fallbackGap != null) {
        margin-top: $fallbackGap;
      } @else {
        margin-top: $gap;
      }
    }
  }
}
