.modal {
  --modalPadding: 36px;
  --windowPadding: 48px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--modalPadding);
  z-index: 9999;
  box-sizing: border-box;
  background: rgba(54, 54, 54, 0.45);

  @supports (backdrop-filter: blur(18px)) {
    backdrop-filter: blur(18px);
  }

  .window {
    max-height: calc(100vh - var(--modalPadding) * 2);
    max-width: max-content;
    background: var(--color-soft-white);
    border-radius: 24px;
    padding: var(--windowPadding);
    box-sizing: border-box;

    .content {
      overflow: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    --modalPadding: 12px;
    --windowPadding: 24px;
  }
}
